.wrapper {
    background-image: url('../../assets/ServicesBackgroundImage.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-blend-mode: overlay;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 20px;
    box-sizing: border-box;
    font-family: 'Times New Roman', Times, serif;
    font-size: 17px;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
    padding: 10px;
}

.heading {
    text-align: center;
    color: #fff;
    font-family: 'Times New Roman', Times, serif;
    font-size: 2rem; /* Adjust based on screen size */
}

.servicesWrapper {
    overflow-x: scroll; /* Enable horizontal scrolling */
    overflow-y: hidden; /* Disable vertical scrolling */
    scroll-behavior: smooth; /* Smooth scrolling */
    max-width: 100%;
    -ms-overflow-style: none;  /* Hide scrollbar in Internet Explorer and Edge */
    scrollbar-width: none;  /* Hide scrollbar in Firefox */
}

.servicesWrapper::-webkit-scrollbar {
    display: none; /* Hide scrollbar in Chrome, Safari, and Opera */
    max-width: 100%;
}

.services {
    display: flex;
    flex-wrap: nowrap; /* Prevent wrapping, forcing the items to be in a single line */
    gap: 20px; /* Space between the cards */
}

.serviceCard {
    flex: 0 0 auto; /* Keep each card's width fixed */
    width: 250px; /* Set a specific width for each card */
    height: 350px; /* Maintain the height */
    box-sizing: border-box;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
}

/* Hover effects */
.serviceCard:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1em;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    pointer-events: none;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .serviceCard {
        flex: 0 1 calc(50% - 20px); /* Two cards per row */
    }
}

@media (max-width: 800px) {
    .serviceCard {
        flex: 0 1 calc(100% - 20px); /* Full-width cards */
        padding: 10px;
    }

    .heading {
        font-size: 1.5rem; /* Smaller font size on smaller screens */
    }
}

@media (max-width: 500px) {
    .wrapper {
        padding: 15px;
    }

    .serviceCard {
        flex: 0 1 100%;
        padding: 8px;
    }

    .heading {
        font-size: 1.2rem;
    }
}

.flag {
    width: 20px;
    height: auto;
    margin-left: 10px;
}

.contentWrapper {
    position: relative;
}

.truncatedContent {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 5em; /* Approximate height of 3 lines */
}

.readMore {
    color: blue;
    cursor: pointer;
    margin-top: 5px;
    display: block;
    font-style: oblique;
}


