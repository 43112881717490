/* HomePage container */
.homePage {
  position: relative;
  height: 100vh;
  overflow: hidden;
  font-family: 'Times New Roman', Times, serif;
}

/* Background GIF styling */
.backgroundGif {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
}

/* Overlay on top of the GIF */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

/* Content container styling */
.content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #fff;
  padding: 0 20px; /* Padding for mobile devices */
  text-align: center;
}

/* Fade-in animation for text */
.fadeText {
  animation: fadeIn 3s forwards; /* Use 'forwards' to keep the final state of the animation */
  color: #fff;
}

/* Styling for the main heading */
h1.fadeText {
  font-size: 65px; /* Increased font size for h1 */
  margin-bottom: 10px;
  line-height: 1.2; /* Adjust line-height if needed for better spacing */
}

/* Styling for the subheading */
h5.fadeText {
  font-size: 26px;
  margin: 0;
}

/* Fade-in keyframes */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Responsive styling for tablets and mobile devices */
@media (max-width: 768px) {
  h1.fadeText {
    font-size: 60px; /* Adjust font size for tablets */
  }
  h5.fadeText {
    font-size: 25px; /* Reduced font size for h5 */
  }
}

@media (max-width: 480px) {
  h1.fadeText {
    font-size: 45px; /* Adjust font size for small devices */
  }
  h5.fadeText {
    font-size: 20px; /* Further reduced font size for small devices */
  }
}
