.roundContainer {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px; /* Adjust the size as needed */
    height: 35px; /* Adjust the size as needed */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.label {
    margin-top: 5px;
    font-size: 0.8rem;
    color: #eee5e5; /* Adjust this color as needed */
}