.card {
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
